import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { animated } from 'react-spring'
import { CustomCheckboxContainer } from '@reach/checkbox'

export const Section = tw.section`flex flex-col md:flex-row w-full bg-gray-100 py-16 md:py-24`
export const Container = tw.div`flex flex-col md:flex-row xl:w-4/5 xxl:w-3/4 xxxl:w-3/5 m-auto`
export const Item = styled.div`
	${tw`relative w-full bg-no-repeat bg-cover h-full`}
	min-height: 300px;
	background-color: #bfbfbf;
	background-image: url(//images.ctfassets.net/ftcnv5up9dxv/4kDlPsonuohdss1Ivp8vdG/a2a624e46826baed6c853e1cf95c136d/BrandImage_Maytronics_HR_M600_PoolsideOnWall_BLOG.jpg?w=500&h=500&q=70&fm=webp&fit=fill&f=center);

	background-position: ${({ bgPosition }) =>
		bgPosition ? bgPosition : `center`};
	padding: ${({ padding }) => (padding ? padding : '4rem')};

	@media (max-width: 1023px) {
		padding: ${({ padding }) => (padding ? padding : '3rem')};
		> form > div > div {
			${tw`w-full px-0`}
		}

		> form > div:last-of-type {
			${tw`px-0`}
		}
	}

	@media (max-width: 767px) {
		background-color: #fff;
	}
	> form > div {
		${tw`m-0`}
	}
`
export const FormItem = styled(Item)`
	${tw`w-10/12 md:w-1/3 h-auto m-auto md:mx-1 lg:mx-2 md:my-0 my-2 md:my-0`}
`
export const ItemBackgroundGradient = styled.div`
	${tw`absolute top-0 left-0 w-full h-full`}
	background: linear-gradient(to bottom, #000 0%,#353e47cc 20%,#353e4700 100%);

	@media (max-width: 767px) {
		/* background: linear-gradient(to left,#1b7db3 0%,#2e8fb1 25%,#58c8df 70%,#83d2e4 100%); */
		background: #58c8df;
	}
`
export const ItemDescription = styled.p`
	${tw`text-3xl lg:text-4xl text-white font-display mt-6`}

	@media(max-width: 767px) {
		${tw`text-dolphin-gray`}
	}
`
export const MultipleItemContainer = styled.div`
	${tw`flex flex-col w-full md:w-1/3 mx-0 md:mx-1 lg:mx-2`}
`
export const MultipleItem = styled.div`
	${tw`h-full bg-center bg-no-repeat bg-cover flex flex-col`}
	margin-bottom: ${({ marginBottom }) =>
		marginBottom ? `${marginBottom} !important` : ''};
	background-image: url('//images.ctfassets.net/ftcnv5up9dxv/6Tf7lBvIBZsdj5Hi541nRs/fc25f16b53fae7c3dbe5c9cf24952d54/BrandImage_Maytronics_HR_M600_PoolEdgeHouseBackground_HP_DEMO.jpg?w=500&h=500&q=70&fm=webp&fit=fill&f=center');
	background-color: #bfbfbf;

	@media(max-width: 767px) {
		min-height: 300px;
	}
`
export const MultipleItemLink = styled(Link)`
	${tw`m-auto w-10/12 md:w-full h-full`}

	&:not(:last-of-type) {
		${tw`lg:mb-4`}
	}
`
export const MultipleItemSplit = styled.div`
	${tw`h-full bg-center bg-no-repeat bg-cover m-auto w-full flex flex-col`}
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.src})` : ``)};
	background-color: #bfbfbf;

	@media (max-width: 767px) {
		min-height: 270px;
	}
`

export const FirstItemText = styled.h3`
	${tw`font-display text-4xl lg:text-5xl`}
	color: ${({ color }) => (color === '#fff' ? color : color)};
`

export const ItemText = styled.p`
	${tw`font-display text-white font-bold flex flex-row items-center justify-center text-lg lg:text-xl`}
	padding : ${({ padding }) => (padding ? padding : '')};
	color: ${({ color }) => (color === '#fff' ? color : color)};
	font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : '')};
	background: ${({ background }) => (background ? background : '')};
`
export const LastItemText = styled(ItemText)`
	${tw`py-8 px-1 lg:px-8 xxl:px-12 font-bold`}
`
export const Logo = tw.img`mr-4`
export const FormTitle = tw.h3`w-full font-display text-4xl text-white font-bold mt-10 mb-4 text-center`
export const FormDescription = tw.p`text-sm font-display text-white mb-8 text-center relative w-full lg:w-11/12 xxxl:w-10/12 mx-auto`
export const PostLink = styled(Link)`
	${tw`w-10/12 md:w-1/3 m-auto md:mx-1 lg:mx-2 md:my-0 my-2 md:my-0`}
`

export const NaturalPoolSection = styled.section`
	height: 100%;
	min-height: 850px;
	${tw`w-full bg-cover bg-no-repeat bg-top`}
	background-color: #c5cbc0;
	background-image: url(${({ sources }) => sources[0].base64});
	@media (min-width: 1024px) and (max-width: 1279px) {
		min-height: 850px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 600px;
	}
	@media (min-width: 525px) and (max-width: 767px) {
		min-height: 500px;
	}
	@media (max-width: 524px) {
		min-height: 400px;
	}
`
export const NaturalPoolContainer = styled.div`
	${tw`flex flex-col items-center w-full h-full py-6 md:py-12 relative`}
	${tw`bg-cover bg-no-repeat bg-top`}
	min-height: 850px;

	background-image: url(${({ sources }) => sources[0].srcWebp});
	background-image: url(${({ sources }) => sources[0].src});

	@media (min-width: 1024px) and (max-width: 1279px) {
		${tw`bg-bottom py-8`}
		background-image: url(${({ sources }) => sources[1].srcWebp});
		background-image: url(${({ sources }) => sources[1].src});
		height: 750px;
		min-height: 750px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		${tw`bg-bottom py-8`}
		background-image: url(${({ sources }) => sources[2].srcWebp});
		background-image: url(${({ sources }) => sources[2].src});
		height: 600px;
		min-height: 600px;
	}
	@media (min-width: 525px) and (max-width: 767px) {
		background-image: url(${({ sources }) => sources[3].srcWebp});
		background-image: url(${({ sources }) => sources[3].src});
		height: 500px;
		min-height: 500px;
	}
	@media (max-width: 524px) {
		background-image: url(${({ sources }) => sources[4].srcWebp});
		background-image: url(${({ sources }) => sources[4].src});
		height: 400px;
		min-height: 400px;
	}
`
export const TextContainer = tw.div`flex flex-col items-center px-2 md:px-0`
export const LinkContainer = styled.div`
	@media (min-width: 525px) {
		${tw`flex-row`}
	}
	${tw`flex flex-col w-10/12 md:w-7/12 xl:w-5/12 mt-2 md:mt-4 items-center justify-center`}

	> a {
		${tw`text-center`}
	}

	> a:first-of-type {
		@media (min-width: 525px) {
			${tw`mr-2 w-auto`}
		}
		${tw`w-full`}
	}
	> a:last-of-type {
		@media (min-width: 525px) {
			${tw`ml-2 w-auto`}
		}
		${tw`w-full`}
	}

	> a:first-of-type button {
		@media (min-width: 525px) {
			${tw`mb-0`}
		}
		${tw`mb-2`}
	}
`
export const BannerTitle = styled.h3`
	@media (min-width: 525px) {
		font-size: 1.5rem;
	}
	${tw`font-display text-2xl lg:text-4xl xxl:text-5xl! text-mineral-teal font-bold uppercase leading-none my-2 md:my-4 text-center`}
	@media (min-width: 1280px) {
		font-size: 2.6rem;
	}
`
export const BannerText = styled.p`
	@media (min-width: 525px) {
		${tw`block`}
	}
	${tw`font-sans text-base lg:text-xl xxl:text-2xl tracking-wide text-gray-700 text-center md:py-2 xl:w-11/12 xxl:w-10/12 leading-snug hidden`}
`
export const BannerButton = styled.button`
	@media (min-width: 525px) {
		${tw`w-auto`}
	}
	${tw`bg-yellow-500 hover:bg-yellow-400 px-6 py-3 md:p-6 rounded leading-none text-gray-700 text-sm md:text-base lg:text-lg font-sans font-medium w-full`}
`
export const SecondBannerButton = styled(BannerButton)`
	${tw`bg-blue-500 hover:bg-blue-400`}
`
export const OzoneLogo = tw.img`w-7/12 md:w-6/12 lg:w-7/12 xl:w-6/12 xxxl:w-5/12`
export const Bold = styled.b`
	${tw`font-bold`}

	@media (max-width: 767px) {
		${tw`text-blue-500`}
	}
`

export const SupportContactContainer = tw.div`flex flex-col md:flex-row w-full`
export const SupportContainer = tw.div`flex flex-col w-full md:w-6/12 py-8 lg:py-16 items-center bg-white`
export const SupportContainerHolder = tw.div`w-11/12 lg:w-11/12 xl:w-4/5 mx-auto`
export const ContactContainer = styled.div`
	${tw`flex flex-col w-full md:w-6/12 p-8 lg:p-16 items-center`}

	background: #66b8d0; /* Old browsers */
	background: linear-gradient(
		to left,
		#1b7db3 0%,
		#2e8fb1 25%,
		#58c8df 70%,
		#83d2e4 100%
	);

	@media (max-width: 767px) {
		background: #58c8df;
	}
`
export const SupportTitle = tw.h3`font-display text-3xl md:text-6xl text-yellow-500 font-bold mb-6 md:mb-2 text-center`
export const SupportSubTitle = tw.p`hidden md:block font-sans text-lg mb-12 xl:w-11/12 xxl:w-full text-center mx-auto`
export const ItemsContainer = tw.div`flex flex-col items-center w-11/12 lg:w-11/12 xl:w-11/12 xxl:w-4/5 mx-auto xxxl:px-8`
export const SupportItem = styled(Link)`
	${tw`flex flex-row border-0 border-black px-2 md:px-4 py-6 w-full bg-gray-100 hover:bg-yellow-50 mb-4 rounded`}
`
export const SvgContainer = tw.div`flex items-center justify-center w-3/12 text-yellow-500`
export const ItemInfo = tw.div`flex flex-col w-9/12`
export const ItemTitle = tw.p`font-display text-xl font-bold mb-2`
export const ItemSub = tw.p`font-display text-xs lg:text-sm font-normal`
export const ContactTitle = tw.h3`font-display text-3xl md:text-6xl text-white font-bold mb-2`
export const ContactDescription = tw.p`font-sans md:text-lg mb-12 text-gray-800 text-center w-11/12`

export const BookButton = styled.button`
	padding: ${({ padding }) => (padding ? padding : '')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '')};
	color: ${({ color }) => (color === '#fff' ? color : color)};
	font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : '')};
	background: ${({ background }) => (background ? background : '')};
`
export const BookLink = styled(Link)`
	${tw`font-display bg-yellow-500 hover:bg-yellow-400 py-2 px-4 rounded`}
`
export const BannerContainer = styled.div`
	${tw`h-full bg-center bg-no-repeat bg-cover flex flex-col m-auto w-10/12 md:w-full h-full mb-2`}
	background-image: ${({ bg }) => (bg ? `url(${bg.fluid.src})` : ``)};
	background-color: #bfbfbf;

	@media(max-width: 767px) {
		min-height: 300px;
	}
`

export const RelaxSection = tw.section`w-full`
export const RelaxContainer = styled.div`
	${tw`w-11/12 xxl:w-9/12 xxxl:w-8/12 mx-auto my-8 md:my-16`}

	> .relax {
		${tw`mx-auto -mt-12 z-10 relative md:-mb-6`}
	}
`
export const SectionTitle = tw.h2`text-yellow-500 text-center text-5xl font-display mb-2`
export const SectionSubtext = tw.h1`text-blue-500 md:text-center text-2xl xl:text-3xl font-display font-bold mb-3 uppercase`
export const TextsContainer = styled.div`
	${tw`flex flex-col md:flex-row`}

	> p {
		${tw`w-full md:w-6/12 leading-normal md:mr-6`}
	}
`
export const Heading4 = tw.h4`text-xl xl:text-2xl md:text-black md:text-center font-display font-bold mb-4 md:mb-8`
export const RightTexts = styled.div`
	${tw`w-full md:w-6/12 mt-4 md:mt-0 md:ml-6`}

	> ul {
		${tw`p-4 bg-blue-200 mb-6`}

		> li {
			${tw`leading-normal relative md:pl-10 mb-2 flex flex-row md:block`}

			> svg {
				${tw`block md:hidden w-8 mt-0.5`}
			}

			> p {
				${tw`w-11/12 md:w-auto ml-2 md:ml-0`}
			}

			&:before {
				content: '';
				${tw`hidden md:block absolute left-0 w-4 h-4 bg-blue-500 rounded-full`}
				top: 4px;
			}
		}
	}

	> p {
		${tw`font-display px-4 xxxl:px-8 text-center text-lg xl:text-2xl text-blue-500 italic font-bold leading-tight xl:leading-none`}
	}
`
export const RelaxImageContainer = styled.div`
	${tw`flex flex-col w-full items-center bg-center bg-no-repeat bg-cover relative overflow-hidden`}
	background-image: url(${({ sources }) => sources[0].base64});
	@media (min-width: 320px) {
		height: 230px;
		max-height: 230px;
	}

	@media (min-width: 525px) {
		height: 320px;
		max-height: 320px;
	}

	@media (min-width: 768px) {
		height: 300px;
		max-height: 300px;
	}

	@media (min-width: 1024px) {
		height: 400px;
		max-height: 400px;
	}

	@media (min-width: 1350px) {
		height: 500px;
		max-height: 500px;
	}
`
export const RelaxImage = styled.div`
	${tw`absolute flex flex-col w-full m-auto items-center bg-center justify-center h-full pb-4 pt-8 lg:p-0 bg-no-repeat bg-cover`}
	top:0;
	left: 0;
	right: 0;

	@media (min-width: 320px) {
		background-image: url(${({ sources }) =>
			sources ? sources[0].srcWebp : ''});
		background-image: url(${({ sources }) => (sources ? sources[0].src : '')});
	}
`
export const DifferenceSection = tw.section`w-full mb-8`
export const DifferenceContainer = tw.div`w-11/12 xxl:w-10/12 mx-auto py-8 md:py-16`
export const DiffTitle = tw.h3`font-display text-2xl md:text-3xl uppercase md:text-center font-bold text-blue-500 md:text-inherit`
export const DiffSubtext = tw.p`font-display text-xl md:text-2xl md:text-center font-bold mb-8`
export const DiffParagraph = tw.p`text-base md:text-xl font-display lg:w-10/12 xl:w-9/12 xxxl:w-8/12 mx-auto md:text-center leading-normal md:leading-none`
export const ListContainer = tw.div``
export const List = tw.ul`flex flex-col md:flex-row`
export const ListItem = styled.li`
	${tw`w-11/12 mx-auto md:w-4/12 px-8 pt-10 pb-16 mb-4 md:mb-0`}
	background-color: ${({ bgColor }) => bgColor};
`
export const ItemName = tw.p`text-white font-display text-xl text-center font-bold max-h-16 xxl:max-h-12 h-full mb-6 md:mb-0`
export const ItemList = styled.ul`
	${tw`w-full xxl:w-10/12 mx-auto`}

	> li {
		${tw`flex flex-row text-white mb-3`}

		> svg {
			${tw`w-1/12`}
		}
		> p {
			${tw`w-11/12 font-display`}
		}
	}
`
export const DiffLastText = tw.p`w-95 xl:w-11/12 xxl:w-10/12 mx-auto md:mt-4 text-center font-display text-sm md:text-base`
export const TechSection = tw.section`w-full py-12 bg-gray-50`
export const TechContainer = tw.div`w-95 lg:w-11/12 xl:w-10/12 xxxl:w-8/12 mx-auto`
export const ItemContainer = styled.div`
	${tw`flex flex-col md:flex-row`}
	&:nth-of-type(2n + 2) {
		> .image {
			${tw`order-1 md:order-2`}
		}
		> .text {
			${tw`order-2 md:order-1`}
		}
	}
	&:not(:last-of-type) {
		${tw`mb-4 md:mb-10`}
	}
`
export const ImageTechContainer = styled.div`
	${tw`w-full md:w-6/12 p-6 bg-center bg-no-repeat bg-contain`}
	background-image: url(${({ src }) => (src ? src : '')});
	background-image: url(${({ srcwebp }) => (srcwebp ? srcwebp : '')});

	@media (max-width:767px) {
		height: 400px;
		max-height: 400px;
	}
`
export const TextTechContainer = styled.div`
	${tw`w-full md:w-6/12 p-3 md:p-6 flex flex-col justify-center`}
	min-height: 500px;
`
export const TechParagraph = styled.p`
	${tw`font-display text-sm md:text-lg`}
`
export const TechList = styled.ul`
	${tw`w-full md:w-10/12 mx-auto my-6 bg-blue-500 md:bg-transparent py-4 md:py-0`}
	> li {
		${tw`pl-6 relative flex flex-row md:block`}

		> svg {
			${tw`mr-2 block md:hidden`}
		}

		&:before {
			content: '';
			${tw`hidden md:block absolute w-3 h-3 rounded-full bg-blue-500 left-0`}
			top: 2px;
		}
		&:not(:last-of-type) {
			${tw`mb-3`}
		}
		> p {
			${tw`text-sm! italic`}
		}
	}

	/* ${tw`w-10/12 mx-auto my-6 bg-blue-500 py-4`}
	> li {
		${tw`pl-6 relative flex flex-row`}

		> svg {
			${tw`mr-2`}
		}

		&:not(:last-of-type) {
			${tw`mb-3`}
		}
		> p {
			${tw`text-sm! italic`}
		}
	} */
`
export const Techtitle = tw.h3`text-2xl md:text-3xl font-display text-blue-500 md:text-yellow-600 mb-1 font-bold text-center uppercase`
export const TechSubText = tw.p`font-display text-xl md:text-2xl font-bold text-center mb-4`
export const TechLearnMore = styled(Link)`
	${tw`cursor-pointer mt-8 border-2 uppercase font-display border-gray-800 font-bold py-3 rounded-lg text-center w-6/12 lg:w-4/12 mx-auto hover:bg-gray-800 hover:text-white`}
`
export const RobotsSection = tw.section`pt-16`
export const RobotsContainer = styled.div`
	${tw`bg-no-repeat bg-right w-95 lg:w-11/12 ml-auto`}
	background-image: url('//images.ctfassets.net/ftcnv5up9dxv/4bCh3PPr8zxqOxGR7Z5Rjp/023905f57df838661b6f8a6bebd2cfe6/Blue_Grids_Background.png?w=1300&h=1400&q=100&fit=fill');
	background-image: url('//images.ctfassets.net/ftcnv5up9dxv/4bCh3PPr8zxqOxGR7Z5Rjp/023905f57df838661b6f8a6bebd2cfe6/Blue_Grids_Background.png?w=1300&h=1400&q=100&fm=webp&fit=fill');
`
export const RobotItem = styled.div`
	&:not(:last-of-type) {
		${tw`mb-8`}
	}
`
export const ResidentialTitle = tw.h3`text-2xl md:text-3xl text-blue-500 font-display font-bold uppercase`
export const ResidentialSub = tw.p`text-xl md:text-2xl text-blue-500 font-display font-bold mb-4`
export const ResidentialSubtext = tw.p`text-base pr-8`
export const ResidentialParagprah = tw.p`mb-8 leading-normal text-base font-display`
export const RobotTextContainer = tw.div`w-full md:w-5/12 xxl:w-6/12 flex flex-col`
export const RobotImageContainer = styled.div`
	${tw`flex-row-reverse flex w-8/12 md:w-7/12 xxl:w-6/12 ml-auto mt-4 md:m-0`}
`
export const ResidentialInnerContainer = tw.div`flex flex-col md:flex-row mt-4 md:mt-10`
export const CommercialTitle = styled(ResidentialTitle)`
	/* ${tw`text-yellow-600`} */
`
export const CommercialSub = styled(ResidentialSub)`
	/* ${tw`text-yellow-600`} */
`
export const CommercialLearnMore = styled(Link)`
	${tw`w-40 bg-yellow-600 hover:bg-yellow-500 rounded-lg uppercase text-center font-bold py-3 font-display`}
`
export const CcSection = styled.section`
	height: 100%;
	min-height: 850px;
	${tw`w-full bg-cover bg-no-repeat mt-12`}
	background-color: #c5cbc0;
	background-position: 100% 70%;
	background-image: url(${({ sources }) => sources[0].base64});
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 600px;
	}
	@media (min-width: 525px) and (max-width: 767px) {
		min-height: 500px;
	}
	@media (max-width: 524px) {
		min-height: 400px;
	}
`
export const CcContainer = styled.div`
	${tw`flex flex-col w-full h-full py-6 md:py-12 relative`}
	${tw`bg-cover bg-no-repeat`}
	background-position: 100% 70%;
	min-height: 850px;

	@media (min-width: 1280px) and (max-width: 1399px) {
		min-height: 750px;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		min-height: 720px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 650px;
	}
	@media (min-width: 320px) and (max-width: 767px) {
		min-height: 450px;
	}
	@media (min-width: 1280px) {
		background-image: url(${({ sources }) => sources[0].srcWebp});
		background-image: url(${({ sources }) => sources[0].src});
	}
	@media (min-width: 768px) and (max-width: 1279px) {
		background-image: url(${({ sources }) => sources[1].srcWebp});
		background-image: url(${({ sources }) => sources[1].src});
	}
	@media (min-width: 320px) and (max-width: 767px) {
		background-image: url(${({ sources }) => sources[2].srcWebp});
		background-image: url(${({ sources }) => sources[2].src});
	}
`
export const CcTextContainer = styled.div`
	${tw`mt-0 md:mt-8 xxxl:mt-4 w-95 xl:w-11/12 xxxl:w-10/12 mx-auto`}

	img {
		${tw`w-40 md:w-64 lg:w-auto`}
		box-shadow: 15px 20px 35px -5px rgba(0,0,0,1);
	}
`
export const CcHook = styled.h2`
	${tw`text-center md:text-left text-4xl md:text-5xl lg:text-6xl xxl:text-7xl font-display text-white font-bold`}
	@media(min-width: 768px) {
		text-shadow: 10px 6px 8px rgba(0, 0, 0, 0.75);
	}
`
export const CcSubtext = styled.p`
	${tw`text-center md:text-left text-3xl md:text-4xl lg:text-5xl xxl:text-6xl font-display text-white font-bold italic mb-8`}
	@media(min-width: 768px) {
		text-shadow: 10px 6px 8px rgba(0, 0, 0, 0.75);
	}
`
export const FeatureContainer = styled.div`
	${tw`flex flex-col md:flex-row bg-white pb-16`}
`
export const FeatureItem = styled.div`
	${tw`w-full md:w-4/12 px-4 flex flex-col items-center mt-8 md:-mt-20 relative`}
	z-index: 1;
	> img {
		${tw`w-32 lg:w-40`}
	}

	> h4 {
		${tw`text-xl text-blue-500 font-bold font-display mt-4`}
	}
`
export const FeatureText = tw.p`w-full md:w-11/12 lg:w-10/12 mx-auto text-center font-display mt-2`
export const TestimonialSection = styled.section`
	${tw`w-full`}
	background: linear-gradient(91deg, rgba(27,125,154,1) 25%, rgba(38,46,61,1) 100%);
`
export const TestimonialContainer = styled.div`
	${tw`w-95 lg:w-10/12 mx-auto py-16 px-4 relative flex flex-col items-center`}

	> h2,
	> p {
		color: #58b8ce;
		${tw`text-2xl md:text-4xl text-center font-display font-bold`}
	}
	> .open-quote,
	.closing-quote {
		color: rgba(255, 255, 255, 0.6);
	}
	> .open-quote {
		${tw`absolute left-0`}
		top: 10%;
		@media (min-width: 320px) and (max-width: 767px) {
			${tw`w-8`}
			top: 0;
		}
		@media (min-width: 768px) and (max-width: 1023px) {
			${tw`w-16`}
		}
		@media (min-width: 1400px) {
			top: 5%;
		}
	}
	> .closing-quote {
		${tw`absolute right-0`}
		bottom: 18%;
		@media (min-width: 320px) and (max-width: 767px) {
			${tw`w-8`}
		}
		@media (min-width: 768px) and (max-width: 1023px) {
			${tw`w-16`}
		}
		@media (min-width: 1400px) {
			bottom: 5%;
		}
	}
`
export const TestimonialContent = styled.div`
	${tw`mt-10 mb-6 relative mb-12 w-full`}
`
export const TestimonialP = tw.p`w-11/12 mx-auto lg:w-full text-white font-display text-lg leading-normal font-extralight text-center`
export const FaqPrev = styled(ChevronLeft)`
	${tw`cursor-pointer absolute`}
	top: 15%;
	left: -6%;
	@media (min-width: 320px) and (max-width: 767px) {
		left: -10%;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		left: -8%;
	}
	@media (min-width: 1400px) {
		left: -5%;
	}
	color: rgba(255, 255, 255, 0.6);
`
export const FaqNext = styled(ChevronRight)`
	${tw`cursor-pointer absolute`}
	top: 15%;
	right: -6%;
	@media (min-width: 320px) and (max-width: 767px) {
		right: -10%;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		right: -8%;
	}
	@media (min-width: 1400px) {
		right: -5%;
	}
	color: rgba(255, 255, 255, 0.6);
`
export const AnimatedContainer = tw.div``
export const AnimatedFaqTitle = animated(AnimatedContainer)
export const Author = tw.p`text-xl font-display text-blue-500 font-bold mt-8 text-center mb-2`
export const AuthorDescription = tw.p`text-xs text-white font-display text-center`
export const MoreTestimonial = styled(Link)`
	${tw`text-sm md:text-base text-yellow-600 rounded-md border border-2 border-yellow-600 hover:text-current hover:bg-yellow-600 text-center uppercase py-2 px-4`}
`
export const WhichModelSection = styled.section`
	height: 100%;
	${tw`w-full bg-cover bg-no-repeat`}
	background-color: #c5cbc0;
	background-position: 100% 70%;
	background-image: url(${({ sources }) => sources[0].base64});
	min-height: 750px;
	@media (min-width: 1280px) and (max-width: 1399px) {
		min-height: 650px;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		min-height: 550px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 550px;
	}
	@media (max-width: 767px) {
		min-height: 450px;
	}
`
export const WhichModelContainer = styled.div`
	${tw`flex flex-col w-full h-full py-6 md:py-12 lg:pt-6 xl:pt-12 relative`}
	${tw`bg-cover bg-no-repeat bg-center`}
	min-height: 750px;
	@media (min-width: 1280px) and (max-width: 1399px) {
		min-height: 650px;
	}
	@media (min-width: 1024px) and (max-width: 1279px) {
		min-height: 550px;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		min-height: 550px;
	}
	@media (max-width: 767px) {
		min-height: 450px;
	}
	@media(min-width: 1700px) {
		background-image: url(${({ sources }) => sources[0].srcWebp});
		background-image: url(${({ sources }) => sources[0].src});
	}
	@media(min-width: 1400px) and (max-width: 1699px) {
		background-image: url(${({ sources }) => sources[1].srcWebp});
		background-image: url(${({ sources }) => sources[1].src});
	}
	@media(min-width: 1280px) and (max-width: 1399px) {
		background-image: url(${({ sources }) => sources[2].srcWebp});
		background-image: url(${({ sources }) => sources[2].src});
	}
	@media(min-width: 1024px) and (max-width: 1279px) {
		${tw`bg-center`}
		background-image: url(${({ sources }) => sources[3].srcWebp});
		background-image: url(${({ sources }) => sources[3].src});
	}
	@media(min-width: 320px) and (max-width: 1023px) {
		${tw`bg-bottom`}
		background-image: url('//images.ctfassets.net/ftcnv5up9dxv/6nYb4hf38SSDVnRaUNRbI6/96fb0d9e5ba61a0fb03f24be0902ebda/which-model-mobile.jpg?w=767&h=1000&q=80&fit=fill&f=bottom');
	}
`
export const WhichModelP = tw.p`text-center text-white font-display leading-normal text-base lg:text-lg`
export const WhichModelTextContainer = tw.div`w-95 lg:w-11/12 lg:w-full mx-auto`
export const WhichModelHook = tw.h2`font-display text-4xl xl:text-5xl text-center font-bold mb-4`
export const WhichModelSubtext = styled.p`
	${tw`font-display text-2xl lg:text-3xl xl:text-4xl text-white text-center font-bold`}

	@media(min-width: 768px) {
		text-shadow: 10px 6px 8px rgba(0, 0, 0, 0.75);
	}
`
export const WhichModelDescription = tw.div`w-11/12 md:w-10/12 lg:w-7/12 xl:w-6/12 xxl:w-5/12 mx-auto mt-8 xl:mt-16 flex flex-col items-center`
export const WhichModelButton = styled(Link)`
	${tw`bg-yellow-500 hover:bg-yellow-400 uppercase py-3 px-4 w-40 font-display text-center font-bold rounded-md mt-10`}
`
export const Italic = tw.i`font-black`
export const Reminder = styled.p`
	${tw`font-display py-2 px-1.5 mx-3 text-black mb-3 text-sm`}

	> a {
		${tw`underline hover:no-underline font-bold`}
	}
`
export const CheckboxContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`
